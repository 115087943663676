var render = function () {
  var _vm$user$profile, _vm$user$id;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "catalog-profile"
  }, [_c('div', {
    staticClass: "catalog-profile__info"
  }, [_c(_vm.linkType, {
    tag: "component",
    staticClass: "catalog-profile__name",
    attrs: {
      "to": _vm.profileLink,
      "href": _vm.profileLink
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.closeAside.apply(null, arguments);
      }
    }
  }, [_vm._v("\n            " + _vm._s(((_vm$user$profile = _vm.user.profile) === null || _vm$user$profile === void 0 ? void 0 : _vm$user$profile.name) || 'Пользователь') + "\n        ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "catalog-profile__id"
  }, [_c('app-copy', {
    staticClass: "catalog-profile__id-copy",
    attrs: {
      "no-icon": "",
      "to-copy": _vm.user.id
    }
  }, [_vm._v("\n            ID\n            "), _c('span', {
    staticClass: "catalog-profile__id-copy-item"
  }, [_vm._v("\n                ..." + _vm._s(((_vm$user$id = _vm.user.id) === null || _vm$user$id === void 0 ? void 0 : _vm$user$id.slice(-6)) || '') + "\n            ")])])], 1), _vm._v(" "), _c('catalog-cabinet-types', {
    staticClass: "catalog-profile__cabinet-type"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }