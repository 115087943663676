import { render, staticRenderFns } from "./-SignUpStepPassword.vue?vue&type=template&id=410b4bb2&"
import script from "./-SignUpStepPassword.vue?vue&type=script&lang=ts&"
export * from "./-SignUpStepPassword.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppInputPassword: require('/var/www/app/src/components/ui/AppInput/AppInputPassword.vue').default,AppButton: require('/var/www/app/src/components/ui/AppButton.vue').default})
