var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "the-catalog"
  }, [_c('div', {
    staticClass: "the-catalog__sections"
  }, [_vm.catalogItems.length ? _vm._l(_vm.catalogItems, function (item) {
    return _c('catalog-item', {
      key: item.alias,
      staticClass: "the-catalog__item",
      attrs: {
        "item": item,
        "aside": _vm.aside
      }
    });
  }) : _vm._l(10, function (item) {
    return _c('catalog-item-skeleton', {
      key: item
    });
  })], 2), _vm._v(" "), _c('app-divider', {
    staticClass: "the-catalog__divider"
  }), _vm._v(" "), _c('catalog-socials', {
    staticClass: "the-catalog__socials"
  }), _vm._v(" "), _vm.widget ? _c('app-widget', {
    staticClass: "the-catalog__widget",
    attrs: {
      "widget": _vm.widget,
      "vertical": ""
    }
  }) : _c('app-widget-skeleton', {
    staticClass: "the-catalog__widget",
    attrs: {
      "vertical": ""
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }