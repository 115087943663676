import { render, staticRenderFns } from "./-CatalogCabinetTypes.vue?vue&type=template&id=f215e34a&scoped=true&"
import script from "./-CatalogCabinetTypes.vue?vue&type=script&lang=ts&"
export * from "./-CatalogCabinetTypes.vue?vue&type=script&lang=ts&"
import style0 from "./-CatalogCabinetTypes.vue?vue&type=style&index=0&id=f215e34a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f215e34a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppTooltip: require('/var/www/app/src/components/ui/AppTooltip.vue').default,AppRadio: require('/var/www/app/src/components/ui/AppRadio.vue').default})
