





























import {
    computed,
    defineComponent,
    onMounted,
    useContext
} from '@nuxtjs/composition-api'

import { userFullName, user } from '@/composables/user'
import { httpLinkRegExp } from '@/composables/regexps'
import { balance, fetchBalances } from '@/composables/user-balance'
import { closeAside } from "@/composables/asides"

import CatalogBalance from './-CatalogBalance.vue'
import CatalogCabinetTypes from '@/components/customer/TheCatalog/-CatalogCabinetTypes.vue'

export default defineComponent({
    components: {
        CatalogCabinetTypes,
        CatalogBalance
    },
    props: {
        profileLink: {
            type: String,
            default: '/profile'
        }
    },
    setup: (props) => {
        const context = useContext()

        const linkType = computed(() => {
            if (httpLinkRegExp.test(props.profileLink || '')) {
                return 'a'
            }

            return 'nuxt-link'
        })

        onMounted(async () => {
            await fetchBalances(context)
        })

        return {
            balance,
            userFullName,
            closeAside,
            linkType,
            user
        }
    }

})
