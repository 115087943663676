





















































import {
    computed,
    defineComponent,
    ref,
    useContext
} from '@nuxtjs/composition-api'

import ChevronIcon from '@/assets/img/chevron.svg'
import QuestionIcon from '@/assets/img/question-circle.svg'

import {
    selectedCabinetId,
    selectedCabinetName,
    cabinetTypesOptions,
    changeCurrentCabinet,
    selectedCabinetValue
} from '@/composables/cabinet-type'
import PersonIcon from '@/assets/img/profiles/person.svg'
import IndividualEntrepreneurIcon from '@/assets/img/profiles/individual-entrepreneur.svg'
import LegalEntityIcon from '@/assets/img/profiles/legal-entity.svg'

export default defineComponent({
    components: {
        ChevronIcon,
        PersonIcon,
        IndividualEntrepreneurIcon,
        LegalEntityIcon,
        QuestionIcon
    },
    setup: () => {
        const context = useContext()
        const showAllCabinets = ref(false)

        const changeCurrentCabinetId = async (cabinetId: string) => {
            await changeCurrentCabinet(cabinetId, context)
        }

        const icon = computed(() => ({
            NaturalPerson: PersonIcon,
            IndividualEntrepreneur: IndividualEntrepreneurIcon,
            LegalEntity: LegalEntityIcon,
            SelfEmployed: PersonIcon
        }[selectedCabinetValue.value as string]))

        return {
            cabinetTypesOptions,
            selectedCabinetId,
            selectedCabinetName,
            icon,
            changeCurrentCabinetId,
            showAllCabinets
        }
    }
})
