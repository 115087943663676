import { render, staticRenderFns } from "./-CatalogProfile.vue?vue&type=template&id=1aba6e08&scoped=true&"
import script from "./-CatalogProfile.vue?vue&type=script&lang=ts&"
export * from "./-CatalogProfile.vue?vue&type=script&lang=ts&"
import style0 from "./-CatalogProfile.vue?vue&type=style&index=0&id=1aba6e08&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aba6e08",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppCopy: require('/var/www/app/src/components/ui/AppCopy.vue').default})
